<template>

  <div
    class="data-visualization"
    role="content">

    <ul class="application-list">

      <li
        v-for="(item, i) in list"
        :key="i"
        @click="checkDetail(item.id)">

        <span class="title">{{ item.title }}
        </span>

        <span class="brief">{{ item.brief }}
        </span>

        <span class="time">{{ item.time }}
        </span>

      </li>

    </ul>

    <el-pagination
      class="pager"
      :current-page="pager.current"
      :page-sizes="pager.sizes"
      :page-size="pager.size"
      :layout="pager.layout"
      :total="pager.total"
      @current-change="changePage"
      @size-change="changeSize">
    </el-pagination>

  </div>

</template>

<script>
import * as api from '@/configs/api'
export default {
  name: 'application-data',
  data () {
    return {
      list: [],
      pager: {
        current: 1,
        layout: 'total, sizes, prev, pager, next, jumper',
        size: 10,
        sizes: [10, 15, 20, 25],
        total: 0
      }
    }
  },
  mounted () {
    this.initData()
  },
  watch: {
    $route: 'initData'
  },
  methods: {
    changePage (e) {
      this.pager.current = e
      this.initData()
    },
    changeSize (e) {
      this.pager.size = e
      this.initData()
    },
    checkDetail (id) {
      const name = this.$route.meta.name
      let url = ''
      switch (name) {
        case 'visualization':
          url = '/data-visualization/detail'
          break
        case 'ai':
          url = '/artificial-intelligence/detail'
          break
        default:
          break
      }
      this.$router.push({
        path: url,
        query: {
          id: id
        }
      })
    },
    initData () {
      const name = this.$route.meta.name
      let type = ''
      switch (name) {
        case 'visualization':
          type = '数据可视化'
          break
        case 'ai':
          type = '人工智能'
          break
        default:
          break
      }
      this.list = []
      const params = new FormData()
      params.append('applicationType', type)
      params.append('publishState', '1')
      params.append('isEn', '1')
      params.append('offset', this.pager.current)
      params.append('limit', this.pager.size)
      this.axios.post(api.application.page, params).then(response => {
        const res = response.data
        if (res.code === 200) {
          this.list = res.data.records.map(item => {
            return {
              brief: item.briefContent,
              id: item.id,
              time: item.createTime.replace(/T/, ' ').replace(/\..*/, ''),
              title: item.title
            }
          })
        }
      }).catch(err => {
        console.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.data-visualization {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  margin: {
    bottom: 50px;
    top: 50px;
  };
  padding: {
    bottom: 50px;
    left: 23px;
    right: 15px;
    top: 20px;
  };
  text-align: left;
}
.application-list {
  flex-grow: 1;
  list-style-type: none;

  li {
    background-color: rgba(249, 251, 255, 1);
    //box-shadow: 0px 0px 10px rgba(0, 0, 0, .3);
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: {
      top: 30px;
    };
    height: 180px;
    padding: {
      bottom: 20px;
      left: 20px;
      right: 20px;
      top: 20px;
    };
    position: relative;
  }
  li:first-child {
    margin: {
      top: 0px;
    };
  }
  .title {
    color: black;
    cursor: pointer;
    flex-shrink: 0;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
  }
  .brief {
    flex-grow: 1;
    margin: {
      bottom: 14px;
      top: 14px;
    };
  }
  .time {
    flex-shrink: 0;
  }
}
.pager {
  flex-shrink: 0;
  margin: {
    top: 30px;
  };
  text-align: center;
}
</style>
